import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import materials from "app/data/materials";
import { Header } from "app/components/header/header";
import * as Checkbox from "@radix-ui/react-checkbox";
import * as Dialog from "@radix-ui/react-dialog";
import { useInView } from "react-intersection-observer";

const filters = {
  applications: [],
  material_form: [],
  credentials: [],
  regional_availability: [],
  manufactured_in: [],
  color_options: [],
  material_characteristics: [],
  technology: [],
  manufacturing: [],
  commercial: [],
  technology_readiness: [],
};

export const filterSettings = {
  applications: { list: true, label: `Applications` },
  credentials: { list: true, label: `Credentials` },
  color_options: { list: true, label: `Color Options` },
  material_characteristics: { list: true, label: `Material Characteristics` },
  material_form: { label: `Material Form` },
  regional_availability: { label: `Regional Availability` },
  manufactured_in: { list: true, label: `Country of Manufacture` },
  technology: { label: `Technology` },
  manufacturing: { label: `Manufacturing Readiness`, tenScale: true },
  commercial: { label: `Commercial Readiness`, tenScale: true },
  technology_readiness: { label: `Technology Readiness`, tenScale: true },
};

const availableFilters = materials.reduce((accumulator, item) => {
  return {
    ...accumulator,
    ...Object.entries(item).reduce((accumulator2, [key, value]) => {
      if (!Object.keys(filters).includes(key) || !value) {
        return accumulator2;
      }

      return {
        ...accumulator,
        ...accumulator2,
        [key]: [
          ...accumulator[key],
          ...(accumulator2[key] || []),
          ...(filterSettings[key]?.list
            ? value.split(`,`).map((subject) => subject.trim())
            : [value]),
        ].filter((subject, index, array) => array.indexOf(subject) === index),
      };
    }, {}),
  };
}, filters);

const Items = ({ handleChange, selected, id, value }) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      <summary className="font-medium text-sm group-open:mb-5 flex items-center after:font-['Material_Icons_Round'] after:!content-['\e5cf'] after:!rotate-0 group-open:after:!rotate-180 after:!ml-auto justify-normal gap-2">
        {Boolean(selected[id]?.length) && (
          <div className="flex group-open:hidden rounded-full border border-white text-[8px] text-white bg-[#C12960] border-2 font-extrabold w-5 h-5 items-center justify-center">
            {selected[id].length}
          </div>
        )}
        {filterSettings[id].label}
      </summary>
      <ul>
        {value.slice(0, showMore ? value.length : 4).map((item) => (
          <li
            key={item}
            className="text-ellipsis overflow-hidden whitespace-nowrap pb-5 flex gap-2 items-center"
          >
            <Checkbox.Root
              onCheckedChange={(checked) => handleChange(id, item, checked)}
              checked={selected[id]?.includes(item) || false}
              id={`${id}_${item}`}
              className="bg-white inline-flex w-6 h-6 justify-center items-center cursor-pointer rounded-md border border-solid border-stone-300 after:font-2xl after:font-['Material_Icons_Round'] after:content-['\e5ca'] after:block after:text-stone-100 aria-checked:after:text-blue-700"
            />
            <label
              htmlFor={`${id}_${item}`}
              title={item}
              className="cursor-pointer ml-2 text-sm text-ellipsis overflow-hidden"
            >
              {item}
            </label>
          </li>
        ))}
        <li>
          <button onClick={() => setShowMore(!showMore)}>
            {showMore ? `-` : `+`} Show {showMore ? `less` : `more`}
          </button>
        </li>
      </ul>
    </>
  );
};

const Filters = ({ data, setFilteredData, inModal }) => {
  const [selected, setSelected] = useState({});
  const [previousSelected, setPreviousSelected] = useState({});
  const handleChange = (group, item, checked) => {
    if (checked) {
      setSelected((previousSelected) => ({
        ...previousSelected,
        [group]: [...(previousSelected?.[group] || []), item].filter(
          (subject, index, array) => array.indexOf(subject) === index,
        ),
      }));
    } else {
      setSelected((previousSelected) => ({
        ...previousSelected,
        [group]: [...previousSelected[group]].filter(
          (subject) => subject !== item,
        ),
      }));
    }
  };

  useEffect(() => {
    if (JSON.stringify(selected) !== JSON.stringify(previousSelected)) {
      setPreviousSelected(selected);
      if (Object.values(selected).every((subject) => !subject.length)) {
        return setFilteredData(data);
      }

      setFilteredData(
        data.filter((item) => {
          return Object.entries(filterSettings).some(([key, { list }]) => {
            return list
              ? item[key]
                  .split(`,`)
                  .map((subject) => subject.trim())
                  .some((thing) => selected[key]?.includes(thing))
              : selected[key]?.includes(item[key]);
          });
        }),
      );
    }
  }, [data, previousSelected, selected, setFilteredData]);

  return (
    <div className={`${inModal ? `block` : `w-60 hidden`} sm:block`}>
      <div className="flex items-center">
        {!inModal && <div className="text-lg">Filters</div>}
        <button
          className="ml-auto bg-white py-2 px-4 rounded text-sm hidden sm:block"
          onClick={() => setSelected({})}
        >
          Clear All
        </button>
      </div>
      {Object.entries(availableFilters).map(([key, value], index) => (
        <details
          key={key}
          open={index === 0}
          className="group py-8 open:pb-16 border-b border-[#C6CCE8]"
        >
          <Items
            value={value}
            id={key}
            handleChange={handleChange}
            selected={selected}
          />
        </details>
      ))}
    </div>
  );
};

const MaterialImage = ({ path, largePath, name, isPlaceholder }) => {
  const [imageZoom, setImageZoom] = useState(false);
  const [delayedLargePath, setDelayedLargePath] = useState();
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && largePath) {
      const image = new Image();

      image.onload = () => setDelayedLargePath(largePath);
      image.src = largePath;
    }
  }, [inView, largePath]);

  return (
    <div
      style={{
        position: `relative`,
        overflow: `hidden`,
      }}
      className="h-[228px]"
      onMouseOver={() => setImageZoom(true)}
      onFocus={() => setImageZoom(true)}
      onMouseOut={() => setImageZoom(false)}
      onBlur={() => setImageZoom(false)}
    >
      <img
        ref={ref}
        src={largePath && delayedLargePath ? delayedLargePath : path}
        alt={name}
        style={{
          height: `100%`,
          width: `auto`,
          maxWidth: `revert`,
          position: `absolute`,
          top: `0`,
          right: `50%`,
          transition: `transform 0.3s ease-out`,
          backfaceVisibility: ``,
          transformOrigin: `right top`,
          transform: `translateX(50%)`,
          ...(largePath &&
            imageZoom && {
              transform: `scale(3) translateX(50%) translateY(-25%)`,
            }),
          ...(isPlaceholder && {
            height: `auto`,
            width: `100%`,
          }),
        }}
      />
    </div>
  );
};

export const Main = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [filteredData, setFilteredData] = useState(materials);

  return (
    <div className="flex flex-col gap-6">
      <Header />

      {/* Main Content */}
      <div className="flex px-[24px] gap-8">
        {/*<FilterPanel setFilteredData={setFilteredData} />*/}
        <Filters data={materials} setFilteredData={setFilteredData} />

        {/* Right Column */}
        <div className="flex-1">
          <div className="pb-[24px] flex items-center">
            <div>
              Displaying {filteredData.length} result
              {filteredData.length > 1 && `s`}
            </div>

            <Dialog.Root open={modalOpen} onOpenChange={setModalOpen}>
              <Dialog.Trigger asChild>
                <button className="ml-auto bg-white py-2 px-4 rounded text-sm sm:hidden">
                  Filters
                </button>
              </Dialog.Trigger>
              <Dialog.Portal>
                <Dialog.Overlay className="fixed bg-black/20 inset-0" />
                <Dialog.Content className="fixed bg-white top-0 left-0 w-full max-h-screen overflow-scroll">
                  <div className="flex p-4 items-start">
                    <div className="flex grow flex-col">
                      <Dialog.Title className="text-lg">Filters</Dialog.Title>
                    </div>
                    <Dialog.Close tabIndex="-1">
                      <i className="material-icons-round text-xl">close</i>
                    </Dialog.Close>
                  </div>
                  <div className="p-4 pb-10">
                    <Filters
                      data={materials}
                      setFilteredData={setFilteredData}
                      inModal
                    />
                    <div className="p-4 fixed bottom-0 left-0 bg-white w-full flex justify-center">
                      <button
                        className="py-2 px-4 rounded text-sm bg-[#173991] text-white"
                        onClick={() => setModalOpen(false)}
                      >
                        Apply Filters
                      </button>
                    </div>
                  </div>
                </Dialog.Content>
              </Dialog.Portal>
            </Dialog.Root>
          </div>
          <div className="grid lg:grid-cols-3 2xl:grid-cols-4 gap-4">
            {filteredData.map((item) => {
              return (
                <div
                  key={item.id}
                  className="p-2 mb-2 rounded-xl bg-white p-[1rem]"
                >
                  <div className="flex flex-col h-full">
                    <MaterialImage
                      path={item.images?.[0].standard}
                      largePath={item.images?.[0].zoom}
                      name={item.nice_name}
                      isPlaceholder={item.images?.[0]?.isPlaceholder}
                    />
                    <div className={`text-[22px] mt-[16px] font-medium`}>
                      {item.nice_name}
                    </div>
                    <p
                      className="line-clamp-3 mb-[16px]"
                      title={item.short_description}
                    >
                      {item.short_description}
                    </p>
                    <div className="mt-auto">
                      <Link
                        to={`/material/${item.id}`}
                        className="whitespace-nowrap relative items-center cursor-pointer rounded-lg px-[12px] py-[8px] hover:bg-[#EFF6FF] text-[#282F44] bg-white border border-black inline-block text-[13px]"
                      >
                        View Product
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

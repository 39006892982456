import React from "react";
import { Icon } from "app/components/icon";

export const Button = ({
  badge,
  iconClassName,
  minimal,
  secondary,
  iconOutlined = false,
  iconGlyph,
  onClick,
  label,
  className,
  children,
  type,
  disabled,
}) => {
  const primaryColorScheme = `hover:bg-[#EFF6FF] text-[#282F44] bg-white border border-black`;
  const secondaryColorScheme = `bg-[#173991] text-white`;
  return (
    <button
      className={`flex whitespace-nowrap relative items-center cursor-pointer rounded-lg px-[12px] py-[8px]  ${
        secondary ? secondaryColorScheme : primaryColorScheme
      }
      ${minimal && `text-[#8086A0] border-0`}
      ${className}`}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {Boolean(badge) && (
        <div
          className={`
          rounded-full 
          bg-[#C12960] border-[1px] border-white 
          h-[12px] w-[12px]
          absolute -top-[4px] -right-[5px] 
          text-[8px] text-white
          `}
        >
          {badge}
        </div>
      )}
      <Icon
        outlined={iconOutlined}
        glyphName={iconGlyph}
        className={`mr-2 text-[#282F44] ${secondary && "text-white"} ${
          !iconGlyph && "hidden "
        } 
        ${minimal && `text-[#C6CCE8] `}
        ${" "} ${iconClassName}
      `}
      />
      <p className="text-[13px]">{label}</p>
      {children}
    </button>
  );
};

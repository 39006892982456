import React from "react";

export const Icon = ({ outlined = false, glyphName, className }) => {
  return (
    <i
      className={`material-icons-${outlined ? "outlined" : "round"} 
      text-xl text-[#282F44] 
      flex justify-center items-center 
      leading-none relative top-[1px] ${className}`}
    >
      {glyphName}
    </i>
  );
};

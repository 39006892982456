import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { MainNav } from "@pentatonic/components";

const envs = {
  localhost: `dev`,
  "material-sourcing.pages.dev": `dev`,
  "materials-sourcing-demo.pentatonic.com": `demo`,
};

export const Header = () => {
  const { loginWithRedirect, logout, user, error } = useAuth0();

  if (error) {
    console.log(error);
  }

  return (
    <MainNav
      appName="Materials Sourcing"
      handleSignIn={loginWithRedirect}
      handleSignOut={() =>
        logout({
          logoutParams: { returnTo: window.location.origin },
        })
      }
      user={user && { ...user, avatar: user.picture }}
      env={envs[window.location.hostname] || `production`}
    />
  );
};

import { useAuth0 } from "@auth0/auth0-react";
import PentatonicLogoBlack from "app/assets/Pentatonic-logo.svg";
import { Button } from "@pentatonic/components";

export const SignInScreen = () => {
  const { user, loginWithRedirect, isLoading: isLoadingAuthState } = useAuth0();

  return user?.email ? null : (
    <div
      className={`
        fixed left-0 right-0 top-0 bottom-0 z-[9999] backdrop-blur-sm
        bg-gradient-to-br md:bg-gradient-to-r from-[rgba(218,242,249,0.70)] via-[rgba(240,218,249,0.70)] to-[rgba(213,248,241,0.70)]
    flex justify-center items-end md:items-center
      `}
    >
      <div
        className={`
          p-[24px] md:m-[24px] w-full md:w-[500px]
          bg-white rounded-t-[16px] md:rounded-[16px] 
          flex flex-col justify-center
          shadow-[0px_24px_24px_-12px_rgba(0,0,0,0.08),0px_0px_24px_4px_rgba(0,0,0,0.08)]
        `}
      >
        <img
          src={PentatonicLogoBlack}
          className="h-[20px] md:h-[20px] mt-[24px]"
          alt="pentatonic logo"
        />
        <h2
          className={`
            text-[29px] text-center pt-[16px] font-semibold	text-[#282F44]
          `}
        >
          Materials Sourcing
        </h2>
        <p className={`text-center my-[24px] text-[16px] text-[#464C64]`}>
          Sign up / sign in below to access Pentatonic&apos;s Materials Sourcing
          platform.
        </p>
        {isLoadingAuthState ? (
          <p className={`text-center my-[24px]`}>Checking sign in</p>
        ) : (
          <Button
            dataTestId="sign-in"
            label={`Sign In / Sign Up`}
            className={`!ml-0 justify-center mt-[16px]`}
            onClick={() =>
              loginWithRedirect({
                appState: {
                  returnTo: window.location.href,
                },
              })
            }
          />
        )}
      </div>
    </div>
  );
};

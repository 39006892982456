import materials from "./materials.json";

const fileSizes = {
  "cork-based-material/data.pdf": `147kb`,
  "cork-based-material/safety.pdf": `147kb`,
  "cellulose-acoustic-panel/data.pdf": `267kb`,
  "compostable-foam/data.pdf": `136kb`,
  "recycled-pet-acoustic-solutions/data.pdf": `362kb`,
  "bio-based-waste-stream-biocomposite/data.pdf": `143kb`,
  "recycled-paper-boards/data.pdf": `214kb`,
  "recycled-paper-boards/safety.pdf": `174kb`,
  "recycled-glass-panels/data.pdf": `140kb`,
  "mycelium-based-acoustic-panels/data.pdf": `197kb`,
  "natural-fibre-boards/data.pdf": `134kb`,
  "natural-fibre-boards-lignin/data.pdf": `144kb`,
  "water-resistant-lignin-coating/data.pdf": `142kb`,
  "recycled-paper-composite/data.pdf": `141kb`,
  "recycled-paper-composite/safety.pdf": `159kb`,
  "recyclable-carton-board/data.pdf": `145kb`,
  "recycled-paper-panel/data.pdf": `139kb`,
  "recycled-paper-panel/safety.pdf": `177kb`,
  "fish-scale-board/data.pdf": `1.8mb`,
};

export default await Promise.all(
  materials.map(async ({ id, ...rest }) => {
    let dataSheet;
    let safetySheet;
    let standardImage;
    let zoomImage;

    try {
      dataSheet = (await import(`../assets/${id}/data.pdf`)).default;
    } catch (_) {
      //
    }

    try {
      safetySheet = (await import(`../assets/${id}/safety.pdf`)).default;
    } catch (_) {
      //
    }

    try {
      standardImage = (await import(`../assets/${id}/block.png`)).default;
    } catch (_) {
      //
    }

    try {
      zoomImage = (await import(`../assets/${id}/block-large.png`)).default;
    } catch (_) {
      //
    }

    const secondaryImages = await Promise.all(
      Array.from(Array(10)).map(async (_, index) => {
        try {
          return (await import(`../assets/${id}/block-${index + 2}.png`))
            .default;
        } catch (_) {
          return Promise.resolve();
        }
      }),
    );

    const images = [
      {
        standard:
          standardImage || (await import(`../assets/placeholder.png`)).default,
        zoom: zoomImage,
        isPlaceholder: !zoomImage,
      },
      ...secondaryImages.filter(Boolean).map((image) => ({ standard: image })),
    ];

    return {
      ...rest,
      id,
      safety_sheet: safetySheet,
      technical_sheet: dataSheet,
      safety_sheet_size: fileSizes[`${id}/safety.pdf`],
      technical_sheet_size: fileSizes[`${id}/data.pdf`],
      images,
    };
  }),
);
// ).filter(({ images }) => images?.[0].standard);
